%bannerVideoSize--standard {
  height: 350px;
  @include media-breakpoint-up(sm) {
    // 576px and up
    height: 350px;
  }
  @include media-breakpoint-up(md) {
    // 768px and
    height: 450px;
  }
  @include media-breakpoint-up(lg) {
    // 992px and up
    height: 40vh;
  }
  @include media-breakpoint-up(xl) {
    // 1200px and up
    height: 49vh;
  }
  @media (min-width: 2201px) {
    //height: 900px;
    height: 700px;
  }
}
