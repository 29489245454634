@import './mixins';
@import './variables';

// plugins
@import './bootstrap/bootstrap';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
//@import '~react-day-picker/lib/style.css';
@import '~react-modal-video/scss/modal-video.scss';

//@import './fonts';
@import './base';
@import './sections';
@import './form';

// top
//@import './layout/hamburger';
//@import './layout/top';
@import './layout/top-navbar';

// bottom
@import './layout/bottomPanel';
@import './layout/bottomMenu';

@import './cms/cms-site';

// modules
@import './modules/breadcrumb';
@import './modules/rotatingBanner';
@import './modules/bannerSize';
@import './modules/pageTiles';
//@import './modules/blogTiles';
@import './modules/slider';
@import './modules/multiColumnPanel';

// components
@import '../components';

@import './site/index';

//aos
@import '~aos/src/sass/aos';

@import './youtubeNew.scss';

@media print {
  @import './print';
}

.appInfo.htmlPanel {
  text-align: center;
}

.cms_item.htmlPanel.bottom-text h5 {
  @include media-breakpoint-up(lg) {
    font-size: 0.7rem;
  }
}

.youtubePlayer--standard, .youtubePlayer--full {
  @media screen and (max-width: 400px) {
    height: 50vh !important;
    //padding-top: 56.25%;
    // iframe.youtubePlayer__iframe {
    //   width: 100% !important;
    //   height: 100% !important;
    //   top: 0px !important;
    //   left: 0px !important;
    // }
  }
}